
const yahooLogo = require('~/assets/images/yahoo.png')
const ignLogo = require('~/assets/images/IGNSEA_White.png')
const moneyfmLogo = require('~/assets/images/moneyfm.png')
const eyeAccordionIcon = require('~/assets/images/landing-page/eye-accordion.svg')
const shape01 = require('~/assets/images/landing-page/shape-01-1.png')

export default {
  data() {
    return {
      eyeAccordionIcon,
      shape01,
      articleList: [
        {
          key: 'yahoo',
          image: yahooLogo,
          url: 'https://sg.news.yahoo.com/content-membership-platform-gank-cosplay-anime-festival-asia-033139601.html',
        },
        {
          key: 'IGNSEA',
          image: ignLogo,
          url: 'https://sea.ign.com/gank/185395/news/how-gank-helps-level-up-your-content-creation-journey-without-taking-a-cut-of-your-income',
        },
        {
          key: 'MoneyFM',
          image: moneyfmLogo,
          url: 'https://omny.fm/shows/moneyfm-afternoon-show/tau-industry-insight-james-sim-gank-18-oct',
        },
      ],
    }
  },
}
